import './App.css';
import { CookieBanner } from "@palmabit/react-cookie-law";

function App() {
  return (
    <div className="App">
      <div className='companyname'>
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
        <div className='companyname-animation'>
          <span style={{'--i':1}}>T</span>
          <span style={{'--i':2}}>i</span>
          <span style={{'--i':3}}>m</span>
          <span style={{'--i':4}}>o</span>
          <span style={{'--i':5}}>k</span>
          <span style={{'--i':6}}>u</span>
        </div>
      </div>
      <div className="info">
        <p><b className="title">Contact</b><br />info(at)timoku.com</p>
        <div className="billing">
        <hr />
          <p><b className="title">Invoicing</b><br />
          <b>Company ID (VAT ID)</b><br />FI32860961</p>
          <p><b>E-invoicing address</b><br />003732860961</p>
          <p><b>Operator</b><br />APIX Messaging Oy<br />
          Operator ID: 003723327487
          </p>
        </div>
      </div>
      <div>
        <CookieBanner
          message="This site uses only necessary session cookies. No user tracking of any kind is implemented."
          wholeDomain={true}
          onAccept={() => {}}
          showStatisticsOption={false}
          showMarketingOption={false}
          showPreferencesOption={false}
          managePreferencesButtonText='Manage'
          privacyPolicyLinkText=''
          acceptButtonText='Accept'
          // onAcceptPreferences={() => {}}
          // onAcceptStatistics={() => {}}
          // onAcceptMarketing={() => {}}
          styles={{
            dialog: { 
              position: 'absolute', 
              bottom: '0px',
              left: '0px',
              right: '0px',
              padding: '20px',
              backgroundColor: '#3f5c7d' },
            button: {
              padding: '10px',
              margin: '5px',
            },
            checkbox: {
              margin: '-10px'
            },
            message: {
              fontSize: '16px',
              color: '#f4f4f4',
              marginTop: '10px',
              marginBottom: '10px'
            }
          }}
        />
      </div>
    </div>
  );
}

export default App;
